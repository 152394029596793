import React from 'react';
import { debounce } from 'lodash';
import { Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

interface ReportNameProps {
	searchParams: URLSearchParams;
}

export function ReportName({ searchParams }: Readonly<ReportNameProps>) {
	const history = useHistory();

	const fieldName = 'report_name';

	function handleReportNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const value = event?.currentTarget?.value;
		debounceReportName(value);
	}

	const debounceReportName = debounce((name: string): void => {
		if (!name) {
			searchParams.delete(fieldName);
		}
		if (name.length >= 5) {
			searchParams.set(fieldName, name);
		}
		history.push({
			search: searchParams.toString()
		});
	}, 800);

	return (
		<Form.Item name={[fieldName]}>
			<Input onChange={handleReportNameChange} placeholder={I18n.get('Report name')} />
		</Form.Item>
	);
}
