import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useApplicationContext } from '@/context/Application';
import { AuthUserDTO, AuthUserRequest } from '@/types/AuthUser';
import { setSession } from '@/redux/auth/actions';
import { useAuth } from '@/hooks/v1/useAuth';
import { LoginForm } from './Form';

export const LoginContent: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { getSession, session } = useAuth();
	const { setUser } = useApplicationContext();

	async function onFinish({ email, password, remember }: AuthUserRequest) {
		const user_session = await getSession(email, password, remember);

		const { user, token, refresh_token } = user_session;

		setUser({ user, token, refresh_token });

		if (is2FALoginValidated(user_session)) {
			return history.push(`/check-two-factor/${user.id}`);
		}

		if (forced2FAIsEnabled(user_session)) {
			return history.push(`/generate-two-factor/${user.id}`);
		}

		dispatch(setSession({ user, token, refresh_token }));
		history.push('/');
	}

	function is2FALoginValidated(userSession: AuthUserDTO) {
		return userSession?.two_fa?.is_2fa_login_enabled && userSession?.two_fa?.validated;
	}

	function forced2FAIsEnabled(userSession: AuthUserDTO) {
		return !!userSession.user.force_two_fa;
	}

	return <LoginForm onFinish={onFinish} isError={session.error} isLoading={session.isLoading} />;
};
