import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Form, Row } from 'antd';

import {
	useCreateREBA,
	useUpdateResult,
	useCreateResult,
	CreateREBAStatus,
	CreateRebaResponse,
	PersonalisedReportResultCreateResponse,
	PersonalisedReportResultUpdateResponse,
	CreateREBADTO
} from '../../../hooks';
import { CreateOrUpdate } from '../../../context/types';
import { usePersonalisedReportContext } from '../../../context';
import { CreateOrUpdateService, InformationsFormFields } from '../../../context/services';

export type createOrUpdateResult = PersonalisedReportResultCreateResponse | PersonalisedReportResultUpdateResponse;

const { useFormInstance } = Form;

export const Footer: React.FC = () => {
	const form = useFormInstance();
	const { personalisedReport, report_id, organization_id, company_id, file_id, step_setting_id, handleNextStep, scrollToError } =
		usePersonalisedReportContext();

	const { mutateAsync: createREBA, isLoading: creatingREBAReport } = useCreateREBA();
	const { mutateAsync: createResult, isLoading: creatingReport } = useCreateResult();
	const { mutateAsync: updateResult, isLoading: updatingReport } = useUpdateResult();

	async function handleNextClick(): Promise<void> {
		try {
			const validation = await form.validateFields();
			if (validation) {
				const values: InformationsFormFields = await form.getFieldValue(['basic_information']);
				const createOrUpdateService = new CreateOrUpdateService();
				const payload = createOrUpdateService.mapPayload(values, {
					file_id,
					report_id,
					company_id,
					organization_id,
					step_setting_id,
					result_id: personalisedReport.result_id
				});
				if (createOrUpdateService.isInformationsPayload(payload)) {
					if (alreadyHasResult()) {
						return await update(payload);
					}
					const report = await createResult(payload);
					if (resultHasHierarchyIds(report)) {
						await handleStep(report);
					}
				}
			}
		} catch (error: any) {
			scrollToError(error);
		}
	}

	function alreadyHasResult(): boolean {
		return !!personalisedReport.result_id;
	}

	async function update(payload: CreateOrUpdate): Promise<void> {
		const report = await updateResult({
			...payload,
			result_id: personalisedReport.result_id
		});
		if (resultHasHierarchyIds(report)) {
			await handleStep(report);
		}
	}

	async function handleStep(report: createOrUpdateResult): Promise<void> {
		if (fileDoesNotHaveREBA() && file_id) {
			const reba = await createREBAForFile(report);
			if (reba.status === CreateREBAStatus.SUCCESS) {
				handleNextStep();
			}
		} else {
			handleNextStep();
		}
	}

	async function createREBAForFile(report: createOrUpdateResult): Promise<CreateRebaResponse> {
		const defaultREBA: CreateREBADTO = {
			file_id,
			force: 1,
			coupling: 1,
			repetition: 1,
			collection_date: moment(),
			sector_id: report.sector_id,
			company_id: report.company_id,
			comment: 'Created from custom report',
			workstation_id: report.workstation_id,
			organization_id: report.organization_id
		};
		return await createREBA(defaultREBA);
	}

	function resultHasHierarchyIds(report: createOrUpdateResult): boolean {
		return (
			!!report.id &&
			!!report.sector_id &&
			!!report.company_id &&
			!!report.workstation_id &&
			!!report.organization_id
		);
	}

	function fileDoesNotHaveREBA(): boolean {
		return !!personalisedReport.basic_information && !personalisedReport.basic_information?.reba?.id;
	}

	return (
		<Row>
			<Col xs={24} style={{ marginTop: '2rem' }}>
				<Row justify="center">
					<Col>
						<Button
							type="primary"
							htmlType="submit"
							onClick={handleNextClick}
							loading={creatingReport || creatingREBAReport || updatingReport}
							disabled={creatingReport || creatingREBAReport || updatingReport}
						>
							{I18n.get('Next')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
