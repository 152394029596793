import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Form, FormProps, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import _ from 'lodash';

import { Text } from '../Typography';
import { FilterCollapse } from '../FilterCollapse';

const { useForm } = Form;

interface FilterCollapseFormProps extends FormProps {
	isCollapseOpen?: boolean;
	onClearClick?: () => void;
	children: React.ReactNode;
	showSearchButton?: boolean;
	disableClearButton: boolean;
	onFilterClick?: (params: any) => void;
}

export function FilterCollapseForm({
	layout,
	children,
	onClearClick,
	onFilterClick,
	disableClearButton,
	isCollapseOpen = false,
	showSearchButton = true,
	...props
}: Readonly<FilterCollapseFormProps>) {
	const [form] = useForm();
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	function handleClear(): void {
		form.resetFields();
		history.push({
			search: 'cleared=true'
		});
	}

	function handleFilter(filterData: any): void {
		const mappedDataToRequest = mapFilterDataToRequest(filterData);
		updateURLParams(mappedDataToRequest);
		if (onFilterClick) {
			onFilterClick(mappedDataToRequest);
		}
	}

	function mapFilterDataToRequest(filterData: any): any {
		const initialValues: any = {
			...filterData
		};
		return initialValues;
	}

	function updateURLParams(params: any): void {
		for (const key in params) {
			searchParams.delete('cleared');
			searchParams.delete(key);
			if (Object.hasOwn(params, key)) {
				const param = params[key];
				if (param && !_.isArray(param)) {
					searchParams.set(key, param);
				} else if (_.isArray(param)) {
					searchParams.set(key, JSON.stringify([...param]));
				}
			}
		}

		history.push({
			search: searchParams.toString()
		});
	}

	return (
		<FilterCollapse isCollapseOpen={isCollapseOpen}>
			<Form form={form} onFinish={handleFilter} layout={layout ?? 'vertical'} {...props}>
				{children}
				<Row gutter={[8, 8]} style={{ paddingTop: '20px' }}>
					<Col xs={24} sm={12} lg={4}>
						<Button
							style={{ width: '100%' }}
							disabled={disableClearButton}
							onClick={onClearClick ?? handleClear}
						>
							<Text>Clear</Text>
						</Button>
					</Col>
					{showSearchButton && (
						<Col xs={24} sm={12} lg={4}>
							<Button
								type="primary"
								htmlType="submit"
								style={{ width: '100%' }}
								icon={<SearchOutlined />}
							>
								{I18n.get('Search')}
							</Button>
						</Col>
					)}
				</Row>
			</Form>
		</FilterCollapse>
	);
}
