import React from 'react';
import type { ParagraphProps } from 'antd/lib/typography/Paragraph';
import type { TitleProps } from 'antd/lib/typography/Title';
import type { TextProps } from 'antd/lib/typography/Text';
import { I18n } from '@aws-amplify/core';
import { Typography } from 'antd';

import type { WrapperTitleProps } from './types';
import * as S from './styles';

export const Title: React.FC<TitleProps & WrapperTitleProps> = ({ children, noTranslate = false, ...props }) => {
	return <S.WrapperTitle {...props}>{noTranslate ? children : I18n.get(children)}</S.WrapperTitle>;
};

export const Paragraph: React.FC<ParagraphProps> = (props) => (
	<Typography.Paragraph {...props}>{I18n.get(props.children)}</Typography.Paragraph>
);

export const Text: React.FC<TextProps> = (props) => (
	<Typography.Text {...props}>{I18n.get(props.children)}</Typography.Text>
);
