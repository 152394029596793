import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import { useGetCellules } from '@/hooks/useGetCellules';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';
import { PlaceholderText } from './styles';

const { useFormInstance, useWatch } = Form;

interface CellulesProps {
	searchParams: URLSearchParams;
}

export function Cellules({ searchParams }: Readonly<CellulesProps>) {
	const history = useHistory();
	const form = useFormInstance();

	const fieldName = HierarchiesField.CELLULE;
	const sectorId = useWatch(HierarchiesField.SECTOR, form);
	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);

	const {
		data: cellules,
		isFetching: isGettingCellules,
		isError: errorGettingCellules
	} = useGetCellules({
		organization_id: organizationId,
		company_id: companyId,
		sector_id: sectorId
	});

	function handleSelectCellule(id: string): void {
		searchParams.delete('cleared');
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			fieldsToReset.forEach((field) => searchParams.delete(field));
			searchParams.set(fieldName, id);
			history.push({
				search: searchParams.toString()
			});
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearCellule(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset) {
			fieldsToReset.forEach((field) => searchParams.delete(field));
			history.push({
				search: searchParams.toString()
			});
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={fieldName} label={I18n.get('Cellule')} labelCol={{ span: 24 }}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={cellules}
				loading={isGettingCellules}
				onClear={handleClearCellule}
				onChange={handleSelectCellule}
				placeholder={<PlaceholderText>Cellule</PlaceholderText>}
				disabled={!sectorId || !cellules || errorGettingCellules}
			/>
		</Form.Item>
	);
}
