export type AuthUserRequest = {
	email: string;
	password: string;
	remember: boolean;
};

export type Auth2FAParams = {
	user_id: string;
	qr_code_url?: string;
	type?: string;
};

export type Role = 'USER' | 'SUPERVISOR' | 'MASTER' | 'ADMIN';

export type TFATypes = 'EMAIL' | 'AUTHENTICATOR';

export type UserDTO = {
	id: string;
	name: string;
	email: string;
	role: Role;
	expiration_plan: Date;
	canceled_on: Date;
	customer_id: string;
	expirationPlan: Date;
	userPlan: string;
	force_two_fa: boolean;
};

export type ForgotPasswordDTO = {
	status: string;
	description: string;
};

export type ResetPasswordDTO = {
	status: string;
	description: string;
};

export type Generate2FADTO = {
	id: string;
	message?: string | undefined;
	qr_code_url?: string | undefined;
	last_email_sent?: Date | undefined;
};

export type TwoFactorAuthenticationDTO = {
	id: string;
	is_2fa_login_enabled: boolean;
	type: TFATypes;
	validated: boolean;
	qr_code_auth_enabled: boolean;
	last_email_sent: Date;
};

export type AuthUserDTO = {
	user: UserDTO;
	token: string;
	two_fa: TwoFactorAuthenticationDTO;
	refresh_token: string;
};

export type SendTokenDTO = {
	message: string;
};

export type ResendTokenDTO = {
	message: string;
};

export type Reset2FADTO = {
	message?: string | undefined;
	qr_code_url?: string | undefined;
};

export type Check2FADTO = {
	token: string;
	refresh_token: string;
	is_2fa_token_correct: boolean;
	last_login: Date;
};

export type Validate2FADTO = {
	validated: boolean;
	is_2fa_login_enabled: boolean;
	tokens_array: string[];
	token: string;
	refresh_token: string;
};

export type ResetPasswordRequest = {
	email: string;
	token: string;
	password: string;
};

export type SendTokenBody = {
	data: {
		email: string;
	};
};

export type ResendTokenBody = {
	data: {
		email: string;
	};
};

export type Reset2FABody = {
	data: {
		token: string;
		type: TFATypes;
	};
};

export type Check2FABody = {
	data: {
		token: string;
		type: TFATypes;
	};
};

export type Validate2FABody = {
	data: {
		token: string;
		type: TFATypes;
	};
};

export type Check2FARequest = Auth2FAParams & Check2FABody;

export type Reset2FARequest = Auth2FAParams & Reset2FABody;

export type Generate2FARequest = Auth2FAParams & {
	type: TFATypes;
};

export type Validate2FARequest = Auth2FAParams & Validate2FABody;

export type ResendTokenRequest = Auth2FAParams & ResendTokenBody;

export type SendTokenRequest = Auth2FAParams & SendTokenBody;

export enum MethodTypes {
	AUTHENTICATOR = 'AUTHENTICATOR',
	EMAIL = 'EMAIL'
}

export enum TFATips {
	TIP_APP = 'You are about to generate your two-factor authentication via the app method. Make sure you are close to your cell phone, with the authentication app open.',
	TIP_EMAIL = 'You are about to generate your two-factor authentication via email method. Make sure you have your inbox open.'
}
