import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import { SelectWithCreation } from '@/components/ui/Inputs';
import { useGetOrganizations } from '@/hooks/useGetOrganizations';
import { hierarchyHandler, HierarchiesField } from '@/utils/HierarchyHandler';
import { PlaceholderText } from './styles';

const { useFormInstance } = Form;

interface OrganizationsProps {
	searchParams: URLSearchParams;
}

export function Organizations({ searchParams }: Readonly<OrganizationsProps>) {
	const history = useHistory();
	const form = useFormInstance();

	const fieldName = HierarchiesField.ORGANIZATION;

	const {
		data: organizations,
		isFetching: isGettingOrganizations,
		isError: errorGettingOrganizations
	} = useGetOrganizations();

	function handleSelectOrganization(id: string): void {
		searchParams.delete('cleared');
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			fieldsToReset.forEach((field) => searchParams.delete(field));
			searchParams.delete('evaluator_id');
			searchParams.set(fieldName, id);
			history.push({
				search: searchParams.toString()
			});
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearOrganization(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset) {
			fieldsToReset.forEach((field) => searchParams.delete(field));
			searchParams.delete('evaluator_id');
			history.push({
				search: searchParams.toString()
			});
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={fieldName} label={I18n.get('Company')} labelCol={{ span: 24 }}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={organizations}
				loading={isGettingOrganizations}
				onClear={handleClearOrganization}
				onChange={handleSelectOrganization}
				placeholder={<PlaceholderText>Company</PlaceholderText>}
				disabled={!organizations || errorGettingOrganizations}
			/>
		</Form.Item>
	);
}
