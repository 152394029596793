import React from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';

import { PlaceholderText } from './styles';
import { useWorkstations } from '@/hooks/v1';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

interface WorkstationsProps {
	searchParams: URLSearchParams;
}

export function Workstations({ searchParams }: Readonly<WorkstationsProps>) {
	const history = useHistory();
	const form = useFormInstance();

	const sectorId = useWatch(HierarchiesField.SECTOR, form);
	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);

	const fieldName = HierarchiesField.WORKSTATION;

	const {
		data: workstations,
		isFetching: isGettingWorkstations,
		isError: errorGettingWorkstations
	} = useWorkstations({
		organization_id: organizationId,
		company_id: companyId,
		sector_id: sectorId
	});

	function handleSelectWorkstation(id: string) {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			fieldsToReset.forEach((field) => searchParams.delete(field));
			searchParams.set(fieldName, id);
			history.push({
				search: searchParams.toString()
			});
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearWorkstation() {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		form.resetFields(fieldsToReset);
		if (fieldsToReset) {
			fieldsToReset.forEach((field) => searchParams.delete(field));
			history.push({
				search: searchParams.toString()
			});
		}
	}

	return (
		<Form.Item name={fieldName}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={workstations}
				loading={isGettingWorkstations}
				onClear={handleClearWorkstation}
				onChange={handleSelectWorkstation}
				placeholder={<PlaceholderText>Workstation</PlaceholderText>}
				disabled={!sectorId || !workstations || errorGettingWorkstations}
			/>
		</Form.Item>
	);
}
