import { Ability, AbilityBuilder } from '@casl/ability';
import type { Actions, Subjects, AbilityInterface, AbilityBuilderInterface } from './types';
import type { RootState } from '@/redux';
import { RoleUserEnum } from '@/utils/enum';
import store from '@/store';

const ability = new Ability<AbilityInterface>();

store.subscribe(() => {
	const rootState: RootState = store.getState();
	const rules = defineRulesFor(rootState.auth);
	ability.update(rules);
});

const defineRulesFor = (auth: any) => {
	const { can, rules } = new AbilityBuilder<AbilityBuilderInterface>(Ability);

	const user = auth?.user?.user;

	if (user?.role === RoleUserEnum.ADMIN) {
		can('create', 'organization');
		can('create', 'company');
		can('settings', 'management');
		can('settings', 'preferences');
		can('delete', 'upload');
		can('management', 'action_plans');
		can('settings', 'plan');
	}

	if (user?.role === RoleUserEnum.MASTER) {
		can('delete', 'upload');
		can('create', 'organization');
		can('create', 'company');
		can('management', 'users');
		can('settings', 'management');
		can('settings', 'force_2fa');
		can('settings', 'preferences');
		can('management', 'action_plans');
		can('settings', ['plan', 'billing']);
		can('settings', 'integrations');
		can('settings', 'personalise_report');
	}

	if (user?.role === RoleUserEnum.SUPERVISOR) {
		can('delete', 'upload');
	}

	return rules;
};

const isAuthorized = (action: Actions, subject: Subjects) => ability.can(action, subject);

export default isAuthorized;
