import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Tooltip } from 'antd';
import { DownloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { ActionsButtonsContainer } from './styles';
import { DownloadVideoIcon } from '../DownloadVideoIcon';

interface InlineProps {
	hasFile: boolean;
	disabled: boolean;
	disableDelete?: boolean;
	onEditReportClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onDeleteReport: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
	onDownloadPDFClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
	onDownloadVideoClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
}

export function Inline({
	hasFile,
	disabled,
	onDeleteReport,
	onEditReportClick,
	onDownloadPDFClick,
	onDownloadVideoClick,
	disableDelete
}: Readonly<InlineProps>) {
	const disabledProps: React.CSSProperties = {
		color: '#ffffff',
		backgroundColor: '#ff4d4f'
	};

	return (
		<ActionsButtonsContainer align="middle" justify="space-evenly">
			<Col lg={5}>
				<Tooltip title={I18n.get('Edit')}>
					<Button type="ghost" onClick={onEditReportClick} icon={<EditOutlined />} />
				</Tooltip>
			</Col>
			<Col lg={5}>
				<Tooltip
					showArrow={!disabled}
					overlayInnerStyle={disabled ? disabledProps : undefined}
					title={I18n.get(disabled ? 'Report incomplete' : 'Download report')}
				>
					<Button type="ghost" onClick={onDownloadPDFClick} disabled icon={<DownloadOutlined />} />
				</Tooltip>
			</Col>
			<Col lg={5}>
				<Tooltip
					showArrow={hasFile}
					overlayInnerStyle={!hasFile ? disabledProps : undefined}
					title={I18n.get(!hasFile ? 'N/A' : 'Download video')}
				>
					<Button
						type="ghost"
						disabled={!hasFile}
						icon={<DownloadVideoIcon />}
						onClick={onDownloadVideoClick}
					/>
				</Tooltip>
			</Col>
			<Col lg={5}>
				<Tooltip title={I18n.get('Delete')}>
					<Button disabled={disableDelete} onClick={onDeleteReport} danger type="ghost" icon={<DeleteOutlined />} />
				</Tooltip>
			</Col>
		</ActionsButtonsContainer>
	);
}
