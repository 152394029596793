import React from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';

import { useEvaluators } from '@/hooks';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField } from '@/utils/HierarchyHandler';
import { PlaceholderText } from './styles';

const { useFormInstance, useWatch } = Form;

interface EvaluatorsProps {
	searchParams: URLSearchParams;
}

export function Evaluators({ searchParams }: Readonly<EvaluatorsProps>) {
	const history = useHistory();
	const form = useFormInstance();

	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);

	const fieldName = 'evaluator_id';

	const {
		data: evaluators,
		isFetching: isGettingEvaluators,
		isError: errorGettingEvaluators
	} = useEvaluators({
		company_id: companyId,
		organization_id: organizationId
	});

	function handleSelectCompany(id: string) {
		if (id) {
			searchParams.set(fieldName, id);
			history.push({
				search: searchParams.toString()
			});
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearCompany() {
		searchParams.delete(fieldName);
		history.push({
			search: searchParams.toString()
		});
		form.resetFields([fieldName]);
	}

	return (
		<Form.Item name={[fieldName]}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={evaluators}
				onClear={handleClearCompany}
				loading={isGettingEvaluators}
				onChange={handleSelectCompany}
				placeholder={<PlaceholderText>Evaluator</PlaceholderText>}
				disabled={!evaluators || !companyId || errorGettingEvaluators}
			/>
		</Form.Item>
	);
}
