import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { CreateChecklistActionPlanRequest, UpdateChecklistActionPlanRequest } from './types/request/actionPlan';
import { ActionPlanTaskDTO, ChecklistActionPlanDTO } from './types/dto/actionPlan';
import { PreliminaryAnalysisByStepName } from './types/dto/preliminaryAnalysis';
import { RebaAnglesResultsDTO, RebaResultsDTO } from './types/dto/reba';
import { ExhibitionDTO } from './types/dto/exhibition';
import { SectorDTO } from './types/dto/sector';
import { UserDTO } from './types/dto/user';
import { FileDTO } from './types/dto/file';
import services from './services';

const {
	getFile,
	getSectors,
	getResults,
	getExhibitions,
	setUpdateStep,
	getRebaAngles,
	setCalculteRisk,
	getFindOrCreateAnalysis,
	getPreliminaryAnalysisList,
	createChecklistActionPlan,
	updateChecklistActionPlan,
	getUsers,
	getActionPlanByStepId,
	getTasks,
	getPreliminaryAnalysisByStepName,
	getReba,
	createStepDefault
} = services;

const useFile = (organizationId: string, companyId: string, fileId: string) => {
	const parameters = { organizationId, companyId, fileId };
	return useQuery<FileDTO, Error>(['file', parameters], () => getFile(parameters), {
		enabled: !!organizationId && !!companyId && !!fileId
	});
};

const useSectors = (organizationId: string, companyId: string) => {
	const parameters = { organizationId, companyId };
	return useQuery<SectorDTO[], Error>(['sectors', parameters], () => getSectors(parameters), {
		enabled: !!organizationId && !!companyId
	});
};

const useRiskLevel = (organizationId: string, companyId: string, fileId?: string, id?: string) => {
	return useMutation((payload: any) => setCalculteRisk({ organizationId, companyId, fileId, id, payload }));
};

const useUpdateStep = (organizationId: string, companyId: string, fileId: string) => {
	return useMutation((payload: any) => setUpdateStep({ organizationId, companyId, fileId, payload }));
};

const usePreliminaryAnalysisList = (organizationId: string, companyId: string) => {
	const parameters = { organizationId, companyId };
	return useQuery<FileDTO[], Error>(['pea-list', parameters], () => getPreliminaryAnalysisList(parameters), {
		enabled: !!organizationId && !!companyId
	});
};

const useFindOrCreateAnalysis = (organizationId: string, companyId: string, fileId: string) => {
	const parameters = { organizationId, companyId, fileId };
	const analysis = useQuery(['preliminary-analysis', { ...parameters }], () => getFindOrCreateAnalysis(parameters), {
		refetchOnWindowFocus: true,
		enabled: !!organizationId && !!companyId && !!fileId
	});
	return {
		...analysis,
		data: analysis.data || ({} as FileDTO)
	};
};

const useGetExhibitions = (organizationId: string, companyId: string, fileId: string) => {
	const parameters = { organizationId, companyId, fileId };
	const exhibitions = useQuery(['exhibitions', parameters], () => getExhibitions(parameters), {
		enabled: !!organizationId && !!companyId
	});
	return {
		...exhibitions,
		data: exhibitions.data || ([] as ExhibitionDTO[])
	};
};

const useRebaAngles = (organizationId: string, companyId: string, fileId?: string) => {
	const payload = { organizationId, companyId, fileId };
	const rebaAngles = useQuery(['reba-angles', payload], () => getRebaAngles(payload), {
		retry: 0,
		enabled: !!organizationId && !!companyId && !!fileId
	});
	return {
		...rebaAngles,
		data: rebaAngles.data || ({} as RebaAnglesResultsDTO)
	};
};

const useGetResults = (organizationId: string, companyId: string, fileId: string) => {
	const parameters = { organizationId, companyId, fileId };
	return useQuery(['preliminary-analysis-results', parameters], () => getResults(parameters), {
		enabled: !!organizationId && !!companyId && !!fileId
	});
};

const useCreateActionPlan = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: CreateChecklistActionPlanRequest }) => createChecklistActionPlan(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['action_plan_by_step']);
			message.success(I18n.get('Action plan created successfully'));
		}
	});
};

const useUpdateActionPlan = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }: { body: UpdateChecklistActionPlanRequest }) => updateChecklistActionPlan(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['action_plan_by_step']);
			message.success(I18n.get('Action plan updated successfully'));
		}
	});
};

const useFindPreliminaryAnalysisByStepName = (
	organizationId: string,
	companyId: string,
	fileId: string | undefined,
	stepName: string,
	levelRisk: number,
	id: string | undefined,
) => {
	const parameters = { organizationId, companyId, fileId, id, payload: stepName };
	const file = useQuery(
		['preliminary_analysis_by_step', stepName],
		() => getPreliminaryAnalysisByStepName(parameters),
		{
			enabled: !!levelRisk
		}
	);
	return {
		...file,
		data: file.data || ({} as PreliminaryAnalysisByStepName)
	};
};

const useFindActionPlanByStepId = (stepId: string, organizationId: string, companyId: string) => {
	const actionPlan = useQuery(
		['action_plan_by_step', stepId],
		() => getActionPlanByStepId({ stepId, organizationId, companyId }),
		{
			enabled: !!stepId
		}
	);
	return {
		...actionPlan,
		data: actionPlan.data || ({} as ChecklistActionPlanDTO)
	};
};

const useFindTasks = (actionPlanId: string, organizationId: string, companyId: string) => {
	const actionPlan = useQuery(['tasks', actionPlanId], () => getTasks({ actionPlanId, organizationId, companyId }), {
		enabled: !!actionPlanId
	});
	return {
		...actionPlan,
		data: actionPlan.data || ([] as ActionPlanTaskDTO[])
	};
};

const useGetUsers = (organizationId: string, companyId: string) => {
	const users = useQuery(
		['responsible_users', { organizationId, companyId }],
		() => getUsers({ organizationId, companyId }),
		{
			enabled: !!organizationId && !!companyId
		}
	);
	return {
		...users,
		data: users.data || ([] as UserDTO[])
	};
};

const useGetReba = (organizationId: string, companyId: string, fileId: string) => {
	const payload = { organizationId, companyId, fileId };
	const reba = useQuery(['checklist_reba', payload], () => getReba(payload), { enabled: !!fileId });
	return {
		...reba,
		data: reba.data || ({} as RebaResultsDTO)
	};
};

const useCreateStepDefault = (organizationId: string, companyId: string, fileId?: string, id?: string) => {
	return useMutation(() => createStepDefault({ organizationId, companyId, fileId, id }));
};

const Hooks = {
	useFile,
	useSectors,
	useRiskLevel,
	useUpdateStep,
	useGetExhibitions,
	useGetResults,
	usePreliminaryAnalysisList,
	useFindOrCreateAnalysis,
	useRebaAngles,
	useCreateActionPlan,
	useUpdateActionPlan,
	useGetUsers,
	useFindActionPlanByStepId,
	useFindTasks,
	useFindPreliminaryAnalysisByStepName,
	useGetReba,
	useCreateStepDefault
};

export default Hooks;
