import React from 'react';
import { I18n } from '@aws-amplify/core';
import { DatePicker, Form } from 'antd';
import { useHistory } from 'react-router-dom';

interface CreatedAtProps {
	searchParams: URLSearchParams;
}

export function CreatedAt({ searchParams }: Readonly<CreatedAtProps>) {
	const history = useHistory();

	function handleSelectDate(start: string, end: string): void {
		searchParams.delete('cleared');
		if (start && end) {
			searchParams.set('start_date', start);
			searchParams.set('end_date', end);
			return history.push({
				search: searchParams.toString()
			});
		}
		searchParams.delete('start_date');
		searchParams.delete('end_date');
		return history.push({
			search: searchParams.toString()
		});
	}

	return (
		<Form.Item name={['created_at']} label={I18n.get('Upload date')} labelCol={{ span: 24 }}>
			<DatePicker.RangePicker
				format="L"
				allowClear
				style={{ width: '100%' }}
				onChange={(_, values) => handleSelectDate(values[0], values[1])}
				disabledDate={(current) => current && current.valueOf() >= Date.now()}
			/>
		</Form.Item>
	);
}
