import { useMutation, useQuery } from '@tanstack/react-query';
import {
	SectorDTO,
	CompanyDTO,
	PEADataDTO,
	PEAListDTO,
	OrganizationDTO,
	PEAQueryParams,
	GetOrganizationDTO
} from './types';
import { MergePeaService } from './service';

const mergePeaService = new MergePeaService();

function useGetOrganization(params: GetOrganizationDTO) {
	const { name } = params;
	const organizations = useQuery<OrganizationDTO[], Error>(['organizations_list', name], () =>
		mergePeaService.getOrganizations(params)
	);
	return {
		...organizations,
		data: organizations.data ?? []
	};
}

function useGetPEAList(queryParams: PEAQueryParams, page?: number, limit?: number) {
	const { organizationId, clearFilter } = queryParams;

	const list = useQuery<{ total: number; result: PEAListDTO[] }, Error>({
		queryKey: ["pea's_list", [organizationId, clearFilter, page, limit]],
		queryFn: () => mergePeaService.getPEAList(queryParams, page, limit),
		retry: 0,
		refetchOnWindowFocus: false
	});
	return {
		...list,
		data: list.data ?? { total: 0, result: [] }
	};
}

function useGetSectors(organizationId: string, companyId: string) {
	const sectors = useQuery<SectorDTO[], Error>({
		queryKey: ['sectors_list'],
		queryFn: () => mergePeaService.getSectors(organizationId, companyId),
		enabled: !!organizationId && !!companyId,
		retry: 0
	});

	return {
		...sectors,
		data: sectors.data ?? []
	};
}

function useGetCompanies(organizationId: string) {
	const companies = useQuery<CompanyDTO[], Error>(
		['companies_list'],
		() => mergePeaService.getCompanies(organizationId),
		{
			enabled: !!organizationId
		}
	);
	return {
		...companies,
		data: companies.data ?? ([] as CompanyDTO[])
	};
}

const useCreateEWA = () => useMutation((payload: PEADataDTO) => mergePeaService.createEWA(payload));

const Hooks = {
	useGetOrganization,
	useGetCompanies,
	useGetPEAList,
	useGetSectors,
	useCreateEWA
};

export default Hooks;
