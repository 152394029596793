import React from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';

import { PlaceholderText } from './styles';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField } from '@/utils/HierarchyHandler';
import { useGetActivities } from '@/hooks/useGetActivities';

const { useFormInstance, useWatch } = Form;

interface ActivityProps {
	searchParams: URLSearchParams;
}

export function Activities({ searchParams }: Readonly<ActivityProps>) {
	const history = useHistory();
	const form = useFormInstance();

	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);
	const workstationId = useWatch(HierarchiesField.WORKSTATION, form);

	const fieldName = 'activity_id';

	const {
		data: activities,
		isFetching: isGettingEvaluators,
		isError: errorGettingEvaluators
	} = useGetActivities({
		company_id: companyId,
		organization_id: organizationId,
		workstation_id: workstationId,
	});

	function handleSelect(id: string) {
		if (id) {
			searchParams.set(fieldName, id);
			history.push({
				search: searchParams.toString()
			});
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClear() {
		searchParams.delete(fieldName);
		history.push({
			search: searchParams.toString()
		});
		form.resetFields([fieldName]);
	}

	return (
		<Form.Item name={[fieldName]}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={activities}
				onClear={handleClear}
				loading={isGettingEvaluators}
				onChange={handleSelect}
				placeholder={<PlaceholderText>Activities</PlaceholderText>}
				disabled={!activities || !workstationId || errorGettingEvaluators}
			/>
		</Form.Item>
	);
}
