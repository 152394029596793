import styled from 'styled-components';
import { Button } from 'antd';

export const CustomButton = styled(Button)`
	width: max-content;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.5rem;

	span {
		font-weight: 600;
	}
`;
