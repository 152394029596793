import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Api from '@/services/api';
import { BaseContext } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';

export type DeleteFileResponse = {
	file_id: string;
};

export type DeleteFileRequest = BaseContext & {
	file_id?: string;
};

async function deleteFile(body: DeleteFileRequest): Promise<DeleteFileResponse> {
	const url = '/file/delete';
	const { data } = await Api.delete<DeleteFileResponse>(url, { params: body });
	return data;
}

export const useDeleteFile = () => {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteFileRequest) => deleteFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILES_LIST]);
			message.success(I18n.get('File deleted successfully'));
		}
	});
};
