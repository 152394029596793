import React from 'react';
import { debounce } from 'lodash';
import { Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

interface FileNameProps {
	searchParams: URLSearchParams;
}

export function FileName({ searchParams }: Readonly<FileNameProps>) {
	const history = useHistory();

	const fieldName = 'original_name';

	function handleFileNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const value = event?.currentTarget?.value;
		debounceFileName(value);
	}

	const debounceFileName = debounce((name: string): void => {
		searchParams.delete('cleared');
		if (!name) {
			searchParams.delete(fieldName);
			history.push({
				search: searchParams.toString()
			});
		}
		if (name.length >= 5) {
			searchParams.set(fieldName, name);
			history.push({
				search: searchParams.toString()
			});
		}
	}, 800);

	return (
		<Form.Item name={[fieldName]} label={I18n.get('Filename')} labelCol={{ span: 24 }}>
			<Input onChange={handleFileNameChange} placeholder={I18n.get('Filename')} />
		</Form.Item>
	);
}
