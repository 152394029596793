import React from 'react';
import { Row } from 'antd';
import { LoginContent } from './LoginContent';
import { Background } from '@/components/ui/Background';

export const Login: React.FC = () => (
	<>
		<Background />
		<Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
			<LoginContent />
		</Row>
	</>
);
