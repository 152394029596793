import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { CustomReportSetting, ErgonomicToolCategory, ErgonomicTool, Response } from '@/types';

export type GetErgonomicToolsCategoriesDTO = BaseContext;

export type ErgonomicToolCategoryResponse = Pick<ErgonomicToolCategory, 'id' | 'name' | 'description' | 'created_at'>;

export type ErgonomicToolResponse = Pick<
	ErgonomicTool,
	'id' | 'name' | 'description' | 'title' | 'subtitle' | 'created_at'
>;

export type CustomReportSettingsResponse = Pick<CustomReportSetting, 'extended_description' | 'custom_report_id'>;

type ErgonomicToolsSettingsResponse = ErgonomicToolResponse & {
	custom_report_settings: CustomReportSettingsResponse;
};

export type ErgonomicToolsCategoriesResponse = ErgonomicToolCategoryResponse & {
	ergonomic_tools: ErgonomicToolsSettingsResponse[];
};

async function fetchErgonomicToolsCategories<T, Body>(params: Body): Promise<T> {
	const url = '/ergonomic-tool/category/find-all';
	const { data } = await Api.get<T>(url, {
		params
	});
	return data;
}

export function useGetErgonomicToolsCategories({ organization_id, company_id }: GetErgonomicToolsCategoriesDTO) {
	const enabled = !!organization_id && !!company_id;
	const ergonomicToolsCategories = useQuery<ErgonomicToolsCategoriesResponse[], AxiosError<Response>>(
		['ergonomic_tools_categories', [organization_id, company_id]],
		() =>
			fetchErgonomicToolsCategories<ErgonomicToolsCategoriesResponse[], GetErgonomicToolsCategoriesDTO>({
				organization_id,
				company_id
			}),
		{
			enabled,
			retry: 0
		}
	);
	return {
		...ergonomicToolsCategories,
		data: ergonomicToolsCategories.data ?? ([] as ErgonomicToolsCategoriesResponse[])
	};
}
