import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';

import { Sectors } from './Sectors';
import { Cellules } from './Cellules';
import { Companies } from './Companies';
import { Evaluators } from './Evaluators';
import { ReportName } from './ReportName';
import { Workstations } from './Workstations';
import { Organizations } from './Organizations';
import { FilterRequest, FormFields } from '../../types';
import { FilterCollapseForm } from '@/components/FilterCollapseForm';
import { Activities } from './Activities';


interface FilterProps {
	queryParams: FilterRequest;
	searchParams: URLSearchParams;
}

export function Filter({ queryParams, searchParams }: Readonly<FilterProps>) {
	const initialValues: FormFields = mapFilterInitialValues(queryParams) ?? {};

	function mapFilterInitialValues(queryParams: FilterRequest): FormFields {
		const { start_date, end_date } = queryParams;

		const created_at = start_date && end_date ? [moment(start_date), moment(end_date)] : undefined;

		const initialValues: FormFields = {
			...queryParams,
			created_at
		};
		return initialValues;
	}

	const queryParamsArray = Object.keys(queryParams);
	const hasSearchParams = queryParamsArray.length > 0;
	const cleared = queryParamsArray[0] === 'cleared';

	return (
		<FilterCollapseForm
			showSearchButton={false}
			initialValues={initialValues}
			isCollapseOpen={hasSearchParams}
			disableClearButton={!hasSearchParams || cleared}
		>
			<Col xs={24}>
				<Row gutter={8}>
					<Col xs={24} lg={8} xxl={6}>
						<Organizations searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Companies searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Sectors searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Cellules searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Workstations searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Activities searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<ReportName searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Evaluators searchParams={searchParams} />
					</Col>
				</Row>
			</Col>
		</FilterCollapseForm>
	);
}
