import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';

import { Text } from '@/components/Typography';
import { CustomDivider, CustomInput } from './styles';

interface OptionsMenuWithCreationProps {
	creatingData?: boolean;
	optionsMenu: React.ReactNode;
	onCreate: (name: string) => void;
}

export function OptionsMenuWithCreation({
	onCreate,
	optionsMenu,
	creatingData = false
}: Readonly<OptionsMenuWithCreationProps>) {
	const [currentName, setCurrentName] = useState<string>('');

	function handleChangeName(name: string) {
		setCurrentName(name);
	}

	function handleCreate(name: string) {
		onCreate(name);
	}

	return (
		<Row gutter={[4, 4]}>
			<Col xs={24}>{optionsMenu}</Col>
			<CustomDivider />
			<Col xs={24}>
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						padding: 8
					}}
				>
					<CustomInput onChange={(event) => handleChangeName(event.target.value)} />

					<Button
						type="link"
						loading={creatingData}
						onClick={() => handleCreate(currentName)}
						disabled={!currentName || currentName.length < 3}
					>
						<Text>Add item</Text>
					</Button>
				</div>
			</Col>
		</Row>
	);
}
