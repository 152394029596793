import React from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';

import { useGetSectors } from '@/hooks/useGetSectors';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';
import { PlaceholderText } from './styles';

const { useFormInstance, useWatch } = Form;

interface SectorsProps {
	searchParams: URLSearchParams;
}

export function Sectors({ searchParams }: Readonly<SectorsProps>) {
	const history = useHistory();
	const form = useFormInstance();

	const fieldName = HierarchiesField.SECTOR;
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);
	const companyId = useWatch(HierarchiesField.COMPANY, form);

	const {
		data: sectors,
		isFetching: isGettingSectors,
		isError: errorGettingSectors
	} = useGetSectors(organizationId, companyId);

	function handleSelectSector(id: string) {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			fieldsToReset.forEach((field) => searchParams.delete(field));
			searchParams.set(fieldName, id);
			history.push({
				search: searchParams.toString()
			});
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearSector() {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		form.resetFields(fieldsToReset);
		if (fieldsToReset) {
			fieldsToReset.forEach((field) => searchParams.delete(field));
			history.push({
				search: searchParams.toString()
			});
		}
	}

	return (
		<Form.Item name={fieldName}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={sectors}
				loading={isGettingSectors}
				onClear={handleClearSector}
				onChange={handleSelectSector}
				placeholder={<PlaceholderText>Sector</PlaceholderText>}
				disabled={!companyId || !sectors || errorGettingSectors}
			/>
		</Form.Item>
	);
}
