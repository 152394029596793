import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from '@/components/ui/Button';
import { Title } from '@/components/Typography';

interface HeaderProps {
	title?: string;
	loading?: boolean;
	noTranslate?: boolean;
	onClick: () => void;
}

export function Header({ title = 'N/A', noTranslate = false, onClick, loading }: Readonly<HeaderProps>) {
	return (
		<Row align="middle">
			<Col span={12}>
				{loading && title === 'N/A' ? (
					<Skeleton.Input active />
				) : (
					<Title level={4} noTranslate={noTranslate} style={{ marginBottom: 0 }}>
						{title}
					</Title>
				)}
			</Col>
			<Col span={12}>
				<Button
					type="primary"
					onClick={onClick}
					icon={<PlusCircleFilled />}
					style={{ display: 'inline', float: 'right' }}
				>
					{I18n.get('Create')}
				</Button>
			</Col>
		</Row>
	);
}
