import { Query, QueryCache, QueryKey } from '@tanstack/react-query';

import { ACTION_PLANS_TYPES, FILTER_SORT_TYPE } from '@/utils/enum';
import { IActionCard, IAllTypes, IQueryData } from '@/types/ActionPlans';

export function filterQueries(type: string, cache: QueryCache): Query[] {
	return cache
		.getAll()
		.filter((item: Query) => {
			const lastElement = item.queryKey[item.queryKey.length - 1];
			return lastElement === type;
		})
		.sort((a: Query, b: Query) => b.state.dataUpdatedAt - a.state.dataUpdatedAt);
}

export function filterTypes(
	type: string,
	queries_array: Query<unknown, unknown, IQueryData, QueryKey>[],
	isDifferent = false
) {
	const query_data = queries_array[0].state.data;
	const action_cards = query_data?.pages[0].action_cards;
	const total_cards = query_data?.pages[0].total_plans;
	const is_query_type = action_cards?.filter((action_card: IActionCard): boolean => action_card.board === type);

	if (is_query_type && action_cards) {
		if (is_query_type.length > 1 && is_query_type.length === total_cards) {
			return action_cards?.filter((action_card: IActionCard): boolean => action_card.board === type);
		}
		if (isDifferent) return action_cards?.filter((action_card: IActionCard): boolean => action_card.board !== type);
	}

	return action_cards?.filter((item: IActionCard): boolean => item.board !== type);
}

export function handleCacheArray(
	different_item_from_array: IActionCard[],
	equal_item_from_array: IActionCard[],
	all_types: IAllTypes
) {
	const type = equal_item_from_array[0]?.board;
	const array_of_type = all_types[type];
	const action_cards_from_type = array_of_type?.state?.data?.pages[0].action_cards;

	const new_array = [...different_item_from_array, ...action_cards_from_type!];

	const remove_old_card_from_array = [...equal_item_from_array];

	return { new_array, remove_old_card_from_array };
}

export function removeFromCacheArray(totalSum: number, removedArray: IActionCard[], queryData: IQueryData) {
	const removed_array_updated = removedArray;
	const total_updated = totalSum - 1;

	queryData.pages[0].action_cards = removed_array_updated;
	queryData.pages[0].total_plans = total_updated;

	return { queryData };
}

export function addInCacheArray(newArray: IActionCard[], totalSumType: number, queryData: IQueryData) {
	const array_updated = newArray;
	const total_sum_updated = totalSumType + 1;

	queryData.pages[0].action_cards = array_updated;
	queryData.pages[0].total_plans = total_sum_updated;

	return { queryData };
}

export function sortArrayType(filteredQueries: Query<unknown, unknown, IQueryData, QueryKey>[]) {
	const request_key = filteredQueries[0].queryKey;
	const is_deadline_date = request_key.includes(FILTER_SORT_TYPE.DEADLINE_DATE);
	const is_worst_score = request_key.includes(FILTER_SORT_TYPE.SCORE);

	const action_cards = filteredQueries[0]?.state?.data?.pages[0].action_cards;

	if (!is_deadline_date && !is_worst_score) {
		return action_cards?.sort((a, b) => {
			const compare_one = a.lexo_rank < b.lexo_rank;
			const compare_two = a.lexo_rank > b.lexo_rank ? 1 : 0;

			return compare_one ? -1 : compare_two;
		});
	}
	return action_cards;
}

export function checkWhichType(
	to_do_filtered_type_different: IActionCard[],
	doing_filtered_type_different: IActionCard[]
) {
	if (to_do_filtered_type_different[0]) {
		return ACTION_PLANS_TYPES.TO_DO;
	}

	if (doing_filtered_type_different[0]) {
		return ACTION_PLANS_TYPES.DOING;
	}

	return ACTION_PLANS_TYPES.DONE;
}
