import { useMutation } from '@tanstack/react-query';
import { AuthService } from '../../services/authService';
import { AuthUserRequest } from '@/types/AuthUser';
import Api from '@/services/api';

const authService = new AuthService();

export function useAuth() {
	const { mutateAsync, isLoading, isError, error } = useMutation(
		(payload: AuthUserRequest) => authService.authUser(payload),
		{
			onSuccess: ({ token }) => {
				Api.defaults.headers.Authorization = `Bearer ${token}`;
			}
		}
	);

	async function getSession(email: string, password: string, remember: boolean) {
		return await mutateAsync({ email, password, remember });
	}

	return {
		getSession,
		session: { isLoading, isError, error: error as any }
	};
}
