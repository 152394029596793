import React, { useEffect, useRef } from 'react';
import { Row, Col, Form, Input, DatePicker } from 'antd';
import { I18n } from '@aws-amplify/core';

import hook from '@/components/Settings/Organizations/hooks';
import hooks from '@/components/views/PreliminaryAnalysis/hooks';
import { Title, Paragraph, Text } from '@/components/Typography';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import { useGetActivities, useGetCellules, useGetOrganization, useEvaluators } from '@/hooks';
import { CelluleSelector } from '@/components/ui/Inputs/CelulleSelect';
import { ActivitySelect } from '@/components/ui/Inputs/ActivitySelect';
import { EvaluatorSelect } from '@/components/ui/Inputs/EvaluatorSelect';

const { useSectors } = hooks;
const { useFormInstance } = Form;
const { useGetWorkstations } = hook;

export const Informations: React.FC = () => {
	const form = useFormInstance();

	const { file, organization_id, company_id } = usePreliminaryAnalysis();
	const { data: organizationData } = useGetOrganization(organization_id);
	const sectors = useSectors(organization_id, company_id);
	const sectorId = Form.useWatch(['file', 'sector_id'], form);
	const workstationId = Form.useWatch(['file', 'workstation_id'], form);
	const evaluatorId = Form.useWatch(['preliminary_analysis', 'evaluator_id'], form);
	const activityId = Form.useWatch(['preliminary_analysis', 'activity_id'], form);
	const celluleId = Form.useWatch(['preliminary_analysis', 'cellule_id'], form);
	const workstations = useGetWorkstations(organization_id, company_id, sectorId);
	const setInicialSector = useRef(form.getFieldValue(['file', 'sector_id']));

	const activities = useGetActivities({
		organization_id: organization_id,
		company_id: company_id,
		workstation_id: workstationId
	});
	const cellules = useGetCellules({
		organization_id: organization_id,
		company_id: company_id,
		sector_id: sectorId
	});
	const evaluators = useEvaluators({
		organization_id: organization_id,
		company_id: company_id
	});

	const handleChangeEvaluator = (e: string) => form.setFieldValue(['preliminary_analysis', 'evaluator_id'], e);
	const handleChangeActivity = (e: string) => form.setFieldValue(['preliminary_analysis', 'activity_id'], e);
	const handleChangeCellule = (e: string) => form.setFieldValue(['preliminary_analysis', 'cellule_id'], e);
	const handleChangeWorkstation = () => form.setFieldValue(['preliminary_analysis', 'activity_id'], null);

	useEffect(() => {
		setInicialSector.current = sectorId;
	}, [sectorId]);

	const clearFunction = () => {
		const newSectorId = form.getFieldValue(['file', 'sector_id']);
		form.resetFields(['file', 'workstation_id']);
		form.setFields([
			{
				name: ['file', 'workstation_id'],
				value: undefined
			},
			{
				name: ['preliminary_analysis', 'activity_id'],
				value: undefined
			},
			{
				name: ['preliminary_analysis', 'cellule_id'],
				value: undefined
			}
		]);
		form.setFields([
			{
				name: ['file', 'sector_id'],
				value: newSectorId
			}
		]);
	};

	return (
		<Row gutter={[0, 10]}>
			<Col span={12} offset={4}>
				<Title level={4}>Informations</Title>
			</Col>
			<Col span={12} offset={4}>
				<Paragraph>Enter the data related to the selected video below</Paragraph>
			</Col>
			<Col span={12} offset={4}>
				<Text strong style={{ display: 'block', padding: '5px 0' }}>
					{I18n.get('Company')}: {organizationData?.name}
				</Text>
				{file?.original_name && (
					<Text strong style={{ display: 'block', padding: '5px 0' }}>
						{I18n.get('File')}: {file?.original_name}
					</Text>
				)}
			</Col>
			<Col xl={12} xs={20} offset={4}>
				<Row gutter={[10, 0]}>
					<Col span={12}>
						<Form.Item
							name={['preliminary_analysis', 'report_name']}
							label={I18n.get('Report name')}
							labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: I18n.get('Enter the report name')
								}
							]}
						>
							<Input placeholder={I18n.get('Report name')} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['file', 'sector_id']}
							label={I18n.get('Sector')}
							rules={[
								{
									required: true,
									message: I18n.get('Select the sector')
								}
							]}
						>
							<SectorSelect
								onChange={clearFunction}
								onClear={clearFunction}
								sectors={sectors}
								loading={sectors.isLoading}
								disabled={sectors.isLoading}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['preliminary_analysis', 'cellule_id']}
							label={I18n.get('Cellule')}
							labelCol={{ span: 24 }}
						>
							<CelluleSelector
								companyId={company_id}
								organizationId={organization_id}
								options={cellules?.data}
								sectorId={sectorId}
								celluleId={celluleId}
								fieldName={'cellule_id'}
								loading={cellules.isLoading}
								disabled={!sectorId}
								handleSelectCellule={handleChangeCellule}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['file', 'workstation_id']}
							label={I18n.get('Workstation')}
							rules={[
								{
									required: true,
									message: I18n.get('Insert the workstation')
								}
							]}
						>
							<WorkstationSelect
								onChange={handleChangeWorkstation}
								sectorId={sectorId}
								loading={sectors.isLoading}
								workstations={workstations?.data}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={I18n.get('Activity')}
							name={['preliminary_analysis', 'activity_id']}
							rules={[
								{
									required: true,
									message: I18n.get('Enter the activity')
								}
							]}
							labelCol={{ span: 24 }}
						>
							<ActivitySelect
								companyId={company_id}
								organizationId={organization_id}
								options={activities?.data}
								workstationId={workstationId}
								activityId={activityId}
								fieldName={'activity_id'}
								loading={activities.isLoading && !!workstationId}
								disabled={!workstationId}
								handleSelectActivity={handleChangeActivity}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['preliminary_analysis', 'role_name']}
							label={I18n.get('Responsibility')}
							rules={[
								{
									required: true,
									message: I18n.get('Insert the responsibility')
								}
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={I18n.get('Collection date')}
							name={['preliminary_analysis', 'collection_date']}
							rules={[
								{
									required: true,
									message: I18n.get('Enter the collection date')
								}
							]}
						>
							<DatePicker
								format={'L'}
								style={{ width: '100%' }}
								placeholder={''}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={I18n.get('Evaluator')}
							name={['preliminary_analysis', 'evaluator_id']}
							rules={[
								{
									required: true,
									message: I18n.get('Select the evaluator')
								}
							]}
							labelCol={{ span: 24 }}
						>
							<EvaluatorSelect
								companyId={company_id}
								organizationId={organization_id}
								disabled={false}
								evaluators={evaluators.data}
								evaluatorId={evaluatorId}
								handleSelectEvaluator={handleChangeEvaluator}
								loading={evaluators.isLoading}
								fieldName={'evaluator_id'}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
