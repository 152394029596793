import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

interface UpdateRulaDTO {
	force: number;
	comment: string;
	file_id: string;
	coupling: number;
	sector_id: string;
	company_id: string;
	repetition: number;
	collection_date: Date;
	workstation_id: string;
	organization_id: string;
}

type UpdateRulaResponse = {
	status: string;
	message: string;
	data: any;
};

type UpdateRulaError = AxiosError<any>;

async function updateReport(parameters: UpdateRulaDTO) {
	const { data } = await Api.put('/ergonomic-tool/rula/basic-info', parameters);
	return data;
}

export const useUpdateBasicInfoRula = () => {
	const queryClient = useQueryClient();
	return useMutation<UpdateRulaResponse, UpdateRulaError, UpdateRulaDTO>((payload) => updateReport(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_REBA_REPORT]);
			queryClient.invalidateQueries(['file']);
		}
	});
};
