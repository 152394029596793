import { Collapse } from "antd";
import styled from "styled-components";

export const CollapseContainer = styled(Collapse)`
	border-radius: 0.5rem;
	border-bottom: 1px solid #d9d9d9;

	.ant-collapse-item {
		border-radius: 0.5rem;
		border: none;

		.ant-collapse-header {
			border-radius: 0.5rem;

			.ant-collapse-header-text {
				width: 100%;
			}
		}

		.ant-collapse-content-active {
			border-radius: 0 0 0.5rem 0.5rem;
		}
	}

	.ant-collapse-item-active {
		border-radius: 0.5rem;
	}
`;