import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';

import { Sectors } from './Sectors';
import { FileName } from './FileName';
import { Cellules } from './Cellules';
import { FormFields } from '../types';
import { Companies } from './Companies';
import { Workstations } from './Workstations';
import { Organizations } from './Organizations';
import { FilterCollapseForm } from '@/components/FilterCollapseForm';
import { CreatedAt } from './CreatedAt';

export type FilterOptions = {
	page?: string;
	rows?: string;
	end_date?: string;
	file_name?: string;
	sector_id?: string;
	cellule_id?: string;
	start_date?: string;
	company_id?: string;
	workstation_id?: string;
	organization_id?: string;
};

export function Filter() {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const queryParams = convertURLParamToObject(searchParams);

	function convertURLParamToObject(searchParams: URLSearchParams): FilterOptions {
		const params = Object.fromEntries(searchParams.entries());
		return params as FilterOptions;
	}

	const initialValues: FormFields = mapFilterInitialValues(queryParams) ?? {};

	function mapFilterInitialValues(queryParams: FilterOptions): FormFields {
		const { start_date, end_date } = queryParams;

		const created_at = start_date && end_date ? [moment(start_date), moment(end_date)] : undefined;

		const initialValues: FormFields = {
			...queryParams,
			created_at
		};
		return initialValues;
	}

	const queryParamsArray = Object.keys(queryParams);
	const hasSearchParams = queryParamsArray.length > 0;
	const cleared = queryParamsArray[0] === 'cleared';

	return (
		<FilterCollapseForm
			showSearchButton={false}
			initialValues={initialValues}
			isCollapseOpen={hasSearchParams}
			disableClearButton={!hasSearchParams || cleared}
		>
			<Col xs={24}>
				<Row gutter={8}>
					<Col xs={24} lg={8} xxl={6}>
						<Organizations searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Companies searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Sectors searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Cellules searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<Workstations searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<FileName searchParams={searchParams} />
					</Col>
					<Col xs={24} lg={8} xxl={6}>
						<CreatedAt searchParams={searchParams} />
					</Col>
				</Row>
			</Col>
		</FilterCollapseForm>
	);
}
